// Buttons

$btn-border-radius: $button-border-radius;

// Example
.btn-primary {
  @include hover() {
    color: $light-white-color !important;
    background-color: $primary-blue-color !important;
    border-color: $primary-blue-color !important;
  }
}

// Button small
.btn-sm {
  min-width: 70px;
  @include button-size(
    0.563rem,
    0.5rem !important,
    0.625rem !important,
    0 !important,
    null
  );
}

// Button medium (default)
.btn-md {
  min-width: 120px;
  @include button-size(
    0.71875rem,
    0.5rem !important,
    0.75rem !important,
    0 !important,
    null
  );
}

// Button large
.btn-lg {
  min-width: 150px;
  @include button-size(
    0.875rem,
    0.5rem !important,
    0.875rem !important,
    0 !important,
    null
  );
}