// Add all variables related content here...

// colors
$primary-blue-color: #0099cc;
$secondary-gray-color: #8493a6;
$success-green-color: #a2d683;
$warning-yellow-color: #ffae5f;
$danger-red-color: #ff5a5a;
$danger-light-red-color: #ffdede;
$light-white-color: #fafafa;
$dark-gray-color: #000000;
$white: #ffffff;

// Fonts

$font-family-base: "Open Sans";

// Buttons

$button-border-radius: 1.875rem;

// Alerts

// Borders
$border-radius: 6px;
